import React from "react";
import styled from "styled-components";

import img1 from "../assets/Images/1.jpg";
import img2 from "../assets/Images/2.jpg";
import img3 from "../assets/Images/3.jpg";

const Section = styled.section`
  position: relative;
  min-height: 100vh;
  width: 80vw;
  display: flex;

  margin: 0 auto;

  @media (max-width: 48em) {
    width: 90vw;
  }
  @media (max-width: 30em) {
    width: 100vw;
  }
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontBig};
  font-family: "Kaushan Script";
  font-weight: 300;

  position: absolute;
  top: 1rem;
  left: 0%;
  z-index: 5;

  @media (max-width: 64em) {
    font-size: ${(props) => `calc(${props.theme.fontBig} - 5vw)`};
    top: 0;
    left: 0;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxxl};
  }
`;
const Left = styled.div`
  width: 50%;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 300;
  position: relative;
  z-index: 5;
  margin-top: 20%;

  @media (max-width: 64em) {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto;

    padding: 2rem;
    font-weight: 600;

    backdrop-filter: blur(2px);
    background-color: ${(props) => `rgba(${props.theme.textRgba},0.4)`};
    border-radius: 20px;
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    width: 70%;
  }
`;
const Right = styled.div`
  width: 50%;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }

  .small-img-1 {
    width: 40%;
    position: absolute;
    right: 15%;
    bottom: 10%;
  }
  .small-img-2 {
    width: 40%;
    position: absolute;
    left: 80%;
    bottom: 30%;
  }

  @media (max-width: 64em) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 100vh;
      object-fit: cover;
    }
    .small-img-1 {
      width: 30%;
      height: auto;
      left: 5%;
      bottom: 10%;
    }
    .small-img-2 {
      width: 30%;
      height: auto;
      left: 60%;
      bottom: 20%;
    }
  }
`;

const About = () => {
  return (
    <Section id="fixed-target" className="about">
      <Title
        data-scroll
        data-scroll-speed="-2"
        data-scroll-direction="horizontal"
      >
        Hakkımızda
      </Title>
      <Left data-scroll data-scroll-sticky data-scroll-target="#fixed-target">
        Sanatın gücünü hayatınıza taşımaya adanmış bir müzik akademisiyiz. Her yaştan ve seviyeden öğrenciye ilham veriyor, müziği yaşamın bir parçası haline getiriyoruz. Enstrüman eğitiminden şan derslerine kadar geniş bir yelpazede bireysel ve grup dersleri sunuyoruz. Müzik, herkesin ruhuna dokunan bir SANATTIR.
        <br />
        <br />
        Deneyimli eğitmenlerimizle kişiye özel eğitimler sağlıyoruz. Her öğrencimizin müzik yolculuğunda benzersiz ve yaratıcı bir deneyim yaşamasını hedefliyoruz. Stillerimiz çeşitli, ama her biri müziğe yeni bir anlam katmak için tasarlandı. Amacımız, size müziğin huzurunu ve coşkusunu yaşatmaktır.
        <br />
        <br />
        Vizyonumuzu sürekli genişletiyoruz. Müzik akademisi olarak, herkesin rahatlıkla öğrenebileceği ve keyif alacağı bir ortam sunmaya özen gösteriyoruz. Sizin için müzikle dolu, ilham verici anlar yaratıyoruz.
      </Left>
      <Right>
        <img src={img1} alt="About Us" />
        <img
          data-scroll
          data-scroll-speed="5"
          src={img2}
          className="small-img-1"
          alt="About Us"
        />
        <img
          data-scroll
          data-scroll-speed="-2"
          src={img3}
          alt="About Us"
          className="small-img-2"
        />
      </Right>
    </Section>
  );
};

export default About;
