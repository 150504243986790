import React, { useState } from 'react';
import styled from "styled-components";


const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  margin: 0 auto 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  position: relative;
`;

const ContactComponent = styled.div`
  width: 80vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 48em) {
    width: 90vw;
    flex-direction: column;
    align-items: center;
  }
`;

const FormContainer = styled.div`
  width: 28%;
  margin: 1%;
  @media (max-width: 48em) {
    width: 100%;
    margin: 0;
  }
`;


const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 1rem;
  }

  label {
    display: flex;
    flex-direction: column;
    font-size: ${(props) => props.theme.fontmd};
    margin-bottom: 0.5rem;
  }

  input,
  textarea {
    padding: 0.5rem;
    font-size: ${(props) => props.theme.fontsm};
    border: 1px solid ${(props) => props.theme.text};
    border-radius: 4px;
    margin-top: 0.5rem;
  }

  button {
    padding: 0.75rem;
    font-size: ${(props) => props.theme.fontmd};
    color: ${(props) => props.theme.body};
    background-color: ${(props) => props.theme.text};
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: ${(props) => props.theme.primary};
    }
  }
`;

const LocationContainer = styled.div`
  width: 68%;
  margin: 1%;
  @media (max-width: 48em) {
    width: 100%;
    margin: 0;
  }

  p {
    font-size: ${(props) => props.theme.fontmd};
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  a {
    color: ${(props) => props.theme.text};
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: ${(props) => props.theme.primary};
    }
  }

  iframe {
    width: 100%;
    height: 550px;
    border: none;
    margin-top: 1rem;
  }
`;

const Contact = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("/favicon.ico", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({ "form-name": "contact", ...state }).toString(),
    })
      .then(() => alert("Mesajınız iletildi. İlginiz için teşekkürler!"))
      .catch((error) => alert(error));
  };

  const { name, email, phone, message } = state;
  return (
    <Section>
      <ContactComponent
        initial={{ y: "-400px" }}
        whileInView={{ y: 0 }}
        viewport={{ once: false }}
        transition={{
          duration: 1.5,
        }}
      >
        
        <FormContainer id="contact-form">
          <StyledForm id="contact" name="contact" method="POST" netlify onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <p>
              <label>Ad & Soyad: <input type="text" name="name" value={name} onChange={handleChange}/></label>
            </p>
            <p>
              <label>E-Posta: <input type="email" name="email" value={email} onChange={handleChange}/></label>
            </p>
            <p>
              <label>Telefon: <input type="phone" name="phone" value={phone} onChange={handleChange}/></label>
            </p>
            <p>
              <label>Mesaj: <textarea name="message" value={message} onChange={handleChange}></textarea></label>
            </p>
            <p>
              <button type="submit">Gönder</button>
            </p>
          </StyledForm>
          <p>
            <b>Adres:</b><br/>
            (YÜKSEK İHTİSAS METRO İSTASYONU KARŞISI)<br/>
            152 Evler Mh. 1.Ertuğrul Sk.Birel Sitesi A Blok Z:3 Yıldırım/BURSA<br/>
            <a href="mailto:bilgi@egitimmuzikakademi.com">bilgi@egitimmuzikakademi.com</a><br/>
            <a href="tel:+902243639944">0 (224) 363 99 44</a><br/>
            <a href="tel:+905523014102">0 (552) 301 41 02</a>
          </p>
        </FormContainer>
        <LocationContainer id="location">
          <iframe
            title="Eğitim Müzik Akademi"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1447.6508014274661!2d29.107771784758974!3d40.19033923010742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb8fb548150913%3A0xf14ce6310946c0ff!2zRcSexLBUxLBNIE3DnFrEsEsgQUtBREVNxLA!5e0!3m2!1str!2str!4v1731589111882!5m2!1str!2str"
            width="100%"
            height="550"
            loading="lazy"
          ></iframe>
        </LocationContainer>
      </ContactComponent>
    </Section>
  );
};

export default Contact;
