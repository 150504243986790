import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect } from "react";
import styled from "styled-components";
import { useRef } from "react";

import { motion } from "framer-motion";

import img1 from "../assets/Images/e-1.jpg";
import img2 from "../assets/Images/e-2.jpg";
import img3 from "../assets/Images/e-3.jpg";
import img4 from "../assets/Images/e-4.jpg";
import img5 from "../assets/Images/e-5.jpg";
import img6 from "../assets/Images/e-6.jpg";
import img7 from "../assets/Images/e-7.jpg";
import img8 from "../assets/Images/e-8.jpg";
import img9 from "../assets/Images/e-9.jpg";
import img10 from "../assets/Images/e-10.jpg";
import img11 from "../assets/Images/e-11.jpg";
import img12 from "../assets/Images/e-12.jpg";

const Section = styled.section`
  min-height: 100vh;
  height: auto;
  width: 100vw;
  margin: 0 auto;
  overflow: hidden;
  background-color: ${(props) => props.theme.grey};

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  position: relative;
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: "Kaushan Script";
  font-weight: 300;
  text-shadow: 1px 1px 1px ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  position: absolute;
  top: 1rem;
  left: 5%;
  z-index: 11;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Left = styled.div`
  width: 35%;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};

  min-height: 100vh;
  z-index: 5;

  position: fixed;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 300;
    width: 80%;
    margin: 0 auto;
  }

  @media (max-width: 64em) {
    p {
      font-size: ${(props) => props.theme.fontmd};
    }
  }

  @media (max-width: 48em) {
    width: 40%;
    p {
      font-size: ${(props) => props.theme.fontsm};
    }
  }

  @media (max-width: 30em) {
    p {
      font-size: ${(props) => props.theme.fontxs};
    }
  }
`;
const Right = styled.div`
  position: absolute;
  left: 35%;
  padding-left: 30%;
  min-height: 100vh;

  background-color: ${(props) => props.theme.grey};
  /* width: 65%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h1 {
    width: 5rem;
    margin: 0 2rem;
  }
`;

const Item = styled(motion.div)`
  width: 20rem;
  margin-right: 6rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
  h1 {
    display: inline-block;
    width: fit-content;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
  }

  @media (max-width: 48em) {
    width: 15rem;
  }
`;

const Product = ({ img, title = "" }) => {
  return (
    <Item
      initial={{ filter: "grayscale(100%)" }}
      whileInView={{ filter: "grayscale(0%)" }}
      transition={{ duration: 0.5 }}
      viewport={{ once: false, amount: "all" }}
    >
      <img src={img} alt={title} />
      <h1>{title}</h1>
    </Item>
  );
};

const Shop = () => {
  gsap.registerPlugin(ScrollTrigger);

  const ref = useRef(null);
  const horizontalRef = useRef(null);

  useLayoutEffect(() => {
    let element = ref.current;
    let scrollingElement = horizontalRef.current;
    window['t']=scrollingElement;
    let pinWrapWidth = scrollingElement.offsetWidth;
    let t1 = gsap.timeline();

    setTimeout(() => {
      t1.to(element, {
        scrollTrigger: {
          trigger: element,
          start: "top top",
          end: pinWrapWidth - scrollingElement.parentElement.offsetWidth + scrollingElement.children[1].offsetWidth,
          scroller: ".App", // locomotive element
          scrub: true,
          pin: true,
          // markers:true,
        },
        // we have to increase scrolling height of this section same as the scrolling element width
        height: `${scrollingElement.scrollWidth}px`,
        ease: "none,",
      });

      // Horizontal Scrolling
      t1.to(scrollingElement, {
        scrollTrigger: {
          trigger: scrollingElement,
          start: "top top",
          end: pinWrapWidth,
          scroller: ".App", // locomotive element
          scrub: true,

          // markers:true,
        },
        // we have to increase scrolling height of this section same as the scrolling element width
        x: -pinWrapWidth,
        ease: "none,",
      });
      ScrollTrigger.refresh();
    }, 1000);

    return () => {
      // Let's clear instances
      t1.kill();
      ScrollTrigger.kill();
    };
  }, []);

  return (
    <Section ref={ref} id="shop">
      <Title data-scroll data-scroll-speed="-1">
        Eğitimlerimiz
      </Title>
      <Left>
        <p>
          Eğitim Müzik Akademisi olarak her yaştan ve seviyeden öğrenciye hitap eden geniş bir eğitim yelpazesi sunuyoruz. Gitar, keman, piyano, yan flüt, çello gibi enstrümanların yanı sıra şan, bağlama, ney ve klarnet gibi özel alanlarda da bireysel ve grup dersleri veriyoruz.
          <br />
          <br />
          Eğitimlerimiz, alanında uzman eğitmenler tarafından kişiye özel programlarla yürütülür. Hedefimiz, her öğrencimizin yeteneklerini en üst seviyeye çıkarırken, müzik yolculuğunda keyifli ve anlamlı bir deneyim yaşamasını sağlamaktır.
        </p>
      </Left>
      <Right ref={horizontalRef}>
        <Product img={img1} title="Gitar" />
        <Product img={img2} title="Keman" />
        <Product img={img3} title="Piyano" />
        <Product img={img4} title="Yan Flüt" />
        <Product img={img5} title="Çello" />
        <Product img={img6} title="Şan" />
        <Product img={img7} title="Bateri" />
        <Product img={img8} title="Bağlama" />
        <Product img={img9} title="Ney" />
        <Product img={img10} title="Klarnet" />
        <Product img={img11} title="Ud" />
        <Product img={img12} title="Kanun" />
      </Right>
    </Section>
  );
};

export default Shop;
